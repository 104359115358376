@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.header {

	@media ($debug) {
		opacity: 0.3;
		background-color: red !important;
	}

	display: flex;
	justify-content: center;
	height: $header-height--small;
	color: $header-fg--small;
	background-color: $header-bg--small;
	transition: height $default-ease,
				box-shadow $default-ease,
				padding $default-ease,
				background-color $default-ease,
				fill $default-ease;
	padding: 10px 0;
	position:fixed;
	width: 100%;
	border-bottom: $header-border-bottom;
	top:0px;
	z-index: 100;

	@include for-watch-only {						// LIGHTHOUSE: satisfy google lighthouse contrast requirements.
		background-color: $hero-dim;
	}

	.header--big & {
		height: $header-height--big;
		background-color: $header-bg--big;
		color: $header-fg--big;
		border-bottom:solid 1px $solid-white;
		padding: 4px 0 2px 0;
		& .header-item--brand {
			transition: width $default-ease, height $default-ease;
			& a .brand-box {
				height: $header-brand-height--big;
			}
		}
	}

	& .header-item--brand {
		transition: width $default-ease, height $default-ease;
		& a {
			& .brand-box {
				display: flex;
				position: relative;
				transition: all $default-ease;
				margin-left: -6px; // push logo left a little to line up with box limits and padding. Most noticable in mobile sizes.
				height: $header-brand-height--small;
			}
		}
	}
}
.header--shadow {
	box-shadow: 0 13px 15px -10px rgba(0,0,0,.2);
}
.header-box {
	display: flex;
	justify-content: flex-start;
	flex: 1 1 auto;
	max-width: $container-max-width;
	align-items: center;
	padding-right: 2rem;	/* leave right margin as standard 4rem as items have margin settings based on the 4rem to keep away from hamburger anyway*/
	@include for-tablet-portrait-up {
		padding-right: 4rem;
	}
}




.header-item {
	display: flex;
	flex: 1 1 auto;
	& button {
		height: 20px;
		margin: 7px 0 0 20px;
	}
	// outline: solid 4px red;
	// outline-offset: -4px;
}
.header-item--nav {
	//align-self: stretch;
	flex-direction: column;
}