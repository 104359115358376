@import "../../styles/variables.scss";
@import "../../styles/media.scss";

[class^="slider-"],
[class*=" slider-"] {
	@media ($debug) {
		outline: solid rgba(0, 140, 255, 0.5) 10px !important;
		outline-offset: -10px;
	}
	background: $hero;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	color: #fff;
	font-weight: 300;
	& h2,
	& h3 {
		font-family: 'Montserrat', sans-serif;
		font-weight: 100;
		margin: 0.2em 0;
		font-size: 1.2em;
		color: #fff;
		text-align: center;
		letter-spacing: -0.05em;
		& span {
			font-family: sans-serif;
			font-weight: 900;
			letter-spacing: 0.05em;
			white-space: nowrap;
		}
	}
	& h2 {
		line-height: 1.1em;
		text-transform: uppercase;
		font-weight: 300;
	}
	& .cta {
		font-size: 0.6em;
		margin: 1em 0;
	}
	position: relative;
	max-height: calc(485px + #{$main-top-small});
	height: 70vw;
	padding: 5px 0;
	font-size: 1.8rem;
	font-size: 4vw;
	@include for-tablet-portrait-up {
		& .cta {
			font-size: 0.3em;
		}
	}
	@include for-desktop-up {
		font-size: 5rem;
		padding: 20px 0;
	}
	& h3 {
		font-size: 0.75em;
	}
}

@keyframes slideInFromLeft {
	0% {	transform: translateX(-100%);	opacity: 0;		}
	10% {	transform: translateX(-100%);	opacity: 0;		}
	100% {	transform: translateX(0);		opacity: 1;		}
}
@keyframes slideInFromLeftSlow {
	0% {	transform: translateX(-100%);	opacity: 0;		}
	50% {	transform: translateX(-100%);	opacity: 0;		}
	100% {	transform: translateX(0);		opacity: 1;		}
}
@keyframes slideInFromBelow {
	0% {	transform: translateY(200%);	opacity: 0;		}
	100% {	transform: translateY(0);		opacity: 1;		}
}
@keyframes fadeInLast {
	0% {	opacity: 0;	}
	60% {	opacity: 0;	}
	100% {	opacity: 1;	}
}
@keyframes fadeOutFast {
	0% {	opacity: 1;	}
	100% {	opacity: 0;	}
}

.slider-01 {
	justify-content: flex-end;										// horizontally align single line items
	&:not(.active) h2, &:not(.active) h3, &:not(.active) .cta {		// negation of .active important of old iOS
		opacity: 0;													// as we handle opacity here (for inactive state) ...
		//transition: opacity 500ms linear;			don't need the overhead of fade out. Use delay to hid avfter slider off-screen
		transition-delay: 1s;
	}
	& h2 {
		max-width: 55vw;
		text-shadow: 0px 2px 10px rgba(0,0,0,0.5), 0px 2px 3px rgba(0,0,0,0.2);
		@include for-desktop-up {
			max-width: 95vw;
		}
	}
	&.active h2 {
		animation: 2300ms ease-out 0s 1 slideInFromLeft;
		opacity: 1;							// we also need to handle opacity here (MUST be after animation for iOS) for the state when active (after annimation is complete)
	}
	&.active h3 {
		animation: 3600ms ease-out 0s 1 slideInFromLeftSlow;
		opacity: 1;
	}
	&.active .cta {
		animation: 4600ms ease-out 0s 1 fadeInLast;
		opacity: 1;
	}
}
.slider-02 {	
	align-items: flex-start;
	justify-content: flex-end;
	& h2, & h3 {
		margin-top: 0;
		margin-bottom: 0;
		text-shadow: 0px 2px 10px rgba(0,0,0,0.5), 0px 2px 3px rgba(0,0,0,0.2);
	}
	& .cta {
		align-self: flex-start;		
	}
	& h2, & h3, & .cta {
		margin-left: 10vw;
	}
	&:not(.active) h2, &:not(.active) h3, &:not(.active) .cta {		// negation of .active important for old iOS
		opacity: 0;
		transition-delay: 1s;
	}
	&.active h2 {
		animation: 2s ease-out 0s 1 slideInFromBelow;
		opacity: 1;
	}
	&.active h3 {
		animation: 2s ease-out 0s 1 slideInFromBelow;
		opacity: 1;
	}
	&.active .cta {
		animation: 3000ms ease-out 0s 1 fadeInLast;
		opacity: 1;
	}
}
.slider-03,
.slider-04,
.slider-05,
.slider-06 {	
	padding-top: 2vw;
	align-items: flex-end;
	justify-content: flex-start;
	& h2, & h3, & .cta {
		margin-left: 10vw;
		margin-right: 10vw;
	}
	& h2, & h3 {
		text-align: end;
		margin-top: 0;
		margin-bottom: 0;
		text-shadow: 0px 2px 10px rgba(0,0,0,0.5), 0px 2px 3px rgba(0,0,0,0.2);
	}
	& .cta {
		align-self: flex-end;
	}
	&:not(.active) h2, &:not(.active) h3, &:not(.active) .cta {		// negation of .active important for old iOS
		opacity: 0;
		transition-delay: 1s;
	}
	&.active h2 {
		animation: 2s ease-out 0s 1 slideInFromBelow;
		opacity: 1;
	}
	&.active h3 {
		animation: 2s ease-out 0s 1 slideInFromBelow;
		opacity: 1;
	}
	&.active .cta {
		animation: 3000ms ease-out 0s 1 fadeInLast;
		opacity: 1;
	}
}



.slider-01 {	.no-webp &,	.webp & {	background-position: 50% 65%;	background-repeat: no-repeat;	background-size: cover;	}	}
.slider-02 {	.no-webp &,	.webp & {	background-position: 50% 23%;	background-repeat: no-repeat;	background-size: cover;	}	}
.slider-03 {	.no-webp &,	.webp & {	background-position: 50% 40%;	background-repeat: no-repeat;	background-size: cover;	}	}
.slider-04 {	.no-webp &,	.webp & {	background-position: 50% 40%;	background-repeat: no-repeat;	background-size: cover;	}	}
.slider-05 {	.no-webp &,	.webp & {	background-position: 50% 37%;	background-repeat: no-repeat;	background-size: cover;	}	}
.slider-06 {	.no-webp &,	.webp & {	background-position: 50% 37%;	background-repeat: no-repeat;	background-size: cover;	}	}


.no-webp {
	& .slider-01 {	background-image: url('../../img/sliders/sunset-sm.jpg');	}
	& .slider-02 {	background-image: url('../../img/sliders/faithfull-business-advice-hands-on-in-the-field-sm.jpg');	}
	& .slider-03 {	background-image: url('../../img/sliders/freight-logistics-sm.jpg');	}
	& .slider-04 {	background-image: url('../../img/sliders/cotton-bud-sm.jpg');	}
	& .slider-05 {	background-image: url('../../img/sliders/understanding-your-operation-sm.jpg');	}
	& .slider-06 {	background-image: url('../../img/sliders/corporate-clients-sm.jpg');	}
}
.webp {
	& .slider-01 {	background-image: url('../../img/sliders/sunset-sm.webp');			}
	& .slider-02 {	background-image: url('../../img/sliders/faithfull-business-advice-hands-on-in-the-field-sm.webp');	}
	& .slider-03 {	background-image: url('../../img/sliders/freight-logistics-sm.webp');	}
	& .slider-04 {	background-image: url('../../img/sliders/cotton-bud-sm.webp');	}
	& .slider-05 {	background-image: url('../../img/sliders/understanding-your-operation-sm.webp');	}
	& .slider-06 {	background-image: url('../../img/sliders/corporate-clients-sm.webp');	}
}
@include for-watch-only {
	.no-webp {
		& .slider-01 {	background-image: url('../../img/sliders/sunset-ty.jpg');		}
		& .slider-02 {	background-image: url('../../img/sliders/faithfull-business-advice-hands-on-in-the-field-ty.jpg');	}
		& .slider-03 {	background-image: url('../../img/sliders/freight-logistics-ty.jpg');	}
		& .slider-04 {	background-image: url('../../img/sliders/cotton-bud-ty.jpg');	}
		& .slider-05 {	background-image: url('../../img/sliders/understanding-your-operation-ty.jpg');	}
		& .slider-06 {	background-image: url('../../img/sliders/corporate-clients-ty.jpg');	}
	}
	.webp {
		& .slider-01 {	background-image: url('../../img/sliders/sunset-ty.webp');		}
		& .slider-02 {	background-image: url('../../img/sliders/faithfull-business-advice-hands-on-in-the-field-ty.webp');	}
		& .slider-03 {	background-image: url('../../img/sliders/freight-logistics-ty.webp');	}
		& .slider-04 {	background-image: url('../../img/sliders/cotton-bud-ty.webp');	}
		& .slider-05 {	background-image: url('../../img/sliders/understanding-your-operation-ty.webp');	}
		& .slider-06 {	background-image: url('../../img/sliders/corporate-clients-ty.webp');	}
	}
}
@include for-tablet-portrait-up {
	.no-webp {
		& .slider-01 {	background-image: url('../../img/sliders/sunset-md.jpg');		}
		& .slider-02 {	background-image: url('../../img/sliders/faithfull-business-advice-hands-on-in-the-field-md.jpg');	}
		& .slider-03 {	background-image: url('../../img/sliders/freight-logistics-md.jpg');	}
		& .slider-04 {	background-image: url('../../img/sliders/cotton-bud-md.jpg');	}
		& .slider-05 {	background-image: url('../../img/sliders/understanding-your-operation-md.jpg');	}
		& .slider-06 {	background-image: url('../../img/sliders/corporate-clients-md.jpg');	}
	}
	.webp {
		& .slider-01 {	background-image: url('../../img/sliders/sunset-md.webp');		}
		& .slider-02 {	background-image: url('../../img/sliders/faithfull-business-advice-hands-on-in-the-field-md.webp');	}
		& .slider-03 {	background-image: url('../../img/sliders/freight-logistics-md.webp');	}
		& .slider-04 {	background-image: url('../../img/sliders/cotton-bud-md.webp');	}
		& .slider-05 {	background-image: url('../../img/sliders/understanding-your-operation-md.webp');	}
		& .slider-06 {	background-image: url('../../img/sliders/corporate-clients-md.webp');	}
	}
}
@include for-desktop-up {
	.no-webp {
		& .slider-01 {	background-image: url('../../img/sliders/sunset-lg.jpg');		}
		& .slider-02 {	background-image: url('../../img/sliders/faithfull-business-advice-hands-on-in-the-field-lg.jpg');	}
		& .slider-03 {	background-image: url('../../img/sliders/freight-logistics-lg.jpg');	}
		& .slider-04 {	background-image: url('../../img/sliders/cotton-bud-lg.jpg');	}
		& .slider-05 {	background-image: url('../../img/sliders/understanding-your-operation-lg.jpg');	}
		& .slider-06 {	background-image: url('../../img/sliders/corporate-clients-lg.jpg');	}
	}
	.webp {
		& .slider-01 {	background-image: url('../../img/sliders/sunset-lg.webp');		}
		& .slider-02 {	background-image: url('../../img/sliders/faithfull-business-advice-hands-on-in-the-field-lg.webp');	}
		& .slider-03 {	background-image: url('../../img/sliders/freight-logistics-lg.webp');	}
		& .slider-04 {	background-image: url('../../img/sliders/cotton-bud-lg.webp');	}
		& .slider-05 {	background-image: url('../../img/sliders/understanding-your-operation-lg.webp');	}
		& .slider-06 {	background-image: url('../../img/sliders/corporate-clients-lg.webp');	}
	}
}





.navigation-wrapper {
	position: relative;
}

.dots {
	display: flex;
	padding: 10px 0;
	justify-content: center;
	@include for-watch-only {
		display: none;
	}
}
.dot {
	border: none;
	width: 10px;
	height: 10px;
	background: #EEE;
	border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
	cursor: pointer;
}
.dot:focus {
	outline: none;
}
.dot.active {
	background: $hero;
}

.arrow {
	width: 30px;
	height: 120px;
	padding: 50px 5px;
	position: absolute;
	top: 57%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	fill: #fff;
	cursor: pointer;
}
.arrow--left {
	left: 0;
}
.arrow--right {
	left: auto;
	right: 0;
}	
@include for-full-menu-up {
	.arrow {
		width: 70px;
		height: 130px;
		padding: 50px 20px;
	}	
}
.arrow--disabled {
	fill: rgba(255, 255, 255, 0.5);
}
