@import "../../styles/variables.scss";
@import "../../styles/media.scss";








/* NAV STYLES - static desktop navigation menu */
.menu-canvas {
	display: block;
	//visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
//	background-color: rgba(51, 255, 0, 0.05);
	z-index: 80;
	// transition: opacity 2s ease, visibility 2s ease;
	// opacity: 0;
	&--active {
		opacity: 1;
		transition: opacity 2s ease, visibility 2s ease;
		visibility: visible;
	}

}
.nav-wrap {
	display: none;
	flex: 1 1 auto;
	z-index: 1;
	@include for-full-menu-up {
		display: flex;
	}
	.nav-menu {
		display: flex;
		flex: 1 1 auto;
		flex-direction: row;
		justify-content: center;
		align-self: center; 	/* SEE: Menu-Items-Vertical-Position - aligns menu items to the middle vertically in the header */
		padding: 0;
		min-width: 250px;
		transition: margin-top $default-ease, padding $default-ease, height $default-ease;
		width: 100%; 							// required for mobile to utilise 100% via MQ in .nav-wrap .menu > div
		height: $nav-height--small;
		.header--big & {
			height: $nav-height--big;
			& > ul.menu-panel > li.menu-item:hover {
				background-color: $nav-hover-button--big;
			}
			& > ul.menu-panel > li.menu-item.active {
				background: $nav-active-button--big;
			}
		}
		& > ul.menu-panel {
			display: flex;
			flex: 1 1 auto;
			justify-content: space-between;
			list-style: none;
			padding: 0;
			max-width: 750px;
			margin: 0;
			-webkit-tap-highlight-color: transparent;
			& > li.menu-item {
				position: relative;
				font-size: 1.8rem;
				transition: font-size $default-ease,
							padding $default-ease,
							color $default-ease;
				display: inline-block;
				z-index: 100;
				border-radius: 3px;
				text-align: center;
				flex-grow: 1;
				margin-left: 5px;
				& a,
				& div.menu-item-link {
					color: inherit;
					text-decoration: none;
					white-space: nowrap;
					font-size: 0.9em;
					width: 100%;
					display: inline-block;
					padding: 10px 0;
					-webkit-tap-highlight-color: transparent;
					cursor: pointer;
				}
				&:hover > a
				&:hover > div.menu-item-link {
					color: $nav-hover-text--small;
					text-decoration: none;
					transition: color 0.4s ease;
				}
				& > ul.sub-menu {
					position: absolute;
					list-style: none;
					display: none;
					top: 45px;
					left: 0px;
					z-index: 100;
					background-color: $solid-white;
					border-radius: 3px;
					box-shadow: 0 13px 15px -10px rgba(0,0,0,.2), 0 0 45px rgba(0,0,0,.1);
					padding: 25px 40px 35px 40px;

					// del this next bit to remove open nav always
					display: flex;
					flex: 1 1 auto;
					flex-direction: column;
					align-items: flex-start;
					z-index: 10;
					& > li.sub-menu-item {
						color: $solid-black;			// default sub menu links in nav bar (not hb) currently sam eof --big and --small (remember, nav bar only)
					}
				}
			}
		}
		& > ul.menu-panel > li.menu-item.active {
			background: $nav-active-button--small;
		}
		& > ul.menu-panel > li.menu-item:hover {
			background-color: $nav-hover-button--small;
			& > ul.sub-menu {
				display: flex;
				flex: 1 1 auto;
				flex-direction: column;
				align-items: flex-start;
				z-index: 10;
				& li.sub-menu-item {
					& a,
					& div.menu-item-link {
						color: inherit;
						text-decoration: none;
						transition: color 0.4s ease;
						width: 100%;
						padding: 10px 0;
						-webkit-tap-highlight-color: transparent;
						cursor: pointer;
						&:hover {
							color: $nav-hover-link;
							text-decoration: underline;
							text-underline-position: under;
						}
					}
					&.active a,
					&.active div {
						color: $nav-active-link;
					}
				}
			}
		}
	}
}