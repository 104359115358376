@import "../styles/variables.scss";
@import "../styles/media.scss";

html {
	height: 100%;
	font-size: 62.5%;
	box-sizing: border-box;
	line-height: 1.6;
    overflow: -moz-scrollbars-vertical; 	// crazy fix for lazy loading affecting (if not applied directly to) keen slider. Without it, the change...
    overflow-y: scroll;						// ... to scroll bar (appearing as lazy content loads) causes slide position to be incrementally out
}

/* montserrat-300 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal; 
	font-display: swap;
	font-weight: 300;
	src: local('Montserrat'),
		 url('../fonts/montserrat-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/montserrat-v15-latin-300.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/montserrat-v15-latin-300.ttf') format('truetype'); /* Safari, Android, iOS */
}

*, *:before, *:after {
	box-sizing: inherit;
	font-family: Montserrat, sans-serif;
}

body {
	margin: 0;
	padding: 0;
	font-family: Montserrat, sans-serif;
	font-weight: 100;
	position: relative;
	font-size: 1.4rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
	color: $hero;
	cursor: pointer;
	&:hover {
		color: $hero-dark;
		text-decoration: underline;
	}
}



@media ($debug) {
	.App {
		background-color: rgba(0, 132, 255, 0.15);
	}
}





section.gap-y {
	padding: 0 0 5em 0;
	margin: 0;
	@include for-tablet-landscape-up {
		padding: 1em 0 5em 0;
		margin: 2em 0;
	}
	&.first {
		padding-top: 0;
		margin-top: 0;
		@include for-tablet-landscape-up {
			padding-top: 1em;
			margin-top: 1em;
		}
	}
	&.last {
		padding-bottom: 5em;
		margin-bottom: 0;
		@include for-tablet-landscape-up {
			padding-bottom: 5em;
			margin-bottom: 2em;
		}
	}
}


section.title {
	background-color: $hero-faint;
	padding-top: 0.8em;
	padding-bottom: 1em;
	margin-bottom: 1.7em;
	color: $dark-slate-grey;
	& .box .cell h1,
	& h6 {
		font-family: 'Montserrat', sans-serif;
		font-weight: 100;
		font-size: 1.2em;
		margin: 0.5em 0 0 0;
		opacity: 0.5;
		letter-spacing: 0;
		line-height: 1.2;
		text-transform: uppercase;
		& span {
			font-weight: 600;
			font-size: 1.03em;
			white-space: nowrap;
			&.normal {									// when <span className="normal"> is used to control heading wrapping only
				font-weight: 100;
				font-size: 1em;
			}
		}
		@include for-watch-only {
			color: $warm-black;
			opacity: 0.9;
			& span {
				white-space: normal;
			}
		}
		@include for-phone-only {
			text-align: center;
		}
	}
	&-bg {												// colours panel under header same colour so header expansion/contraction does not show white
		background-color: $hero-faint;
	}

}
@include for-tablet-landscape-up {
	section.title {
		padding-top: 1em;
		padding-bottom: 1.5em;
		margin-bottom: 0em;	
		&-neat {
			margin-bottom: 0em;
		}
		& .box .cell h1,
		& h6 {
			font-size: 1.3em;
			margin: 0.5em 0 0em 0;
		}
	}
}


.flx {
	@media ($debug) {
		//outline: solid 1px magenta;
		//outline-offset: -1px;
		background-color: rgba(255, 0, 255, 0.3);
	}
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	& .flx--left {
		align-items: flex-start;
	}
	& .flx-col {
		flex: 1 1 auto;
		margin: 0;
		width: 100%;
	}
	@include for-tablet-landscape-up {
		flex-direction: row;
	}
	&.flx-col-lock {
		flex-direction: column;
	}
	&--centre {
		justify-content: center;
	}
}




.box {
	@media ($debug) {
		//background: rgba(0, 255, 0, 0.2);
		outline: dashed 2px lime;
		outline-offset: -2px;
	}
	width: 100%;
	max-width: $container-max-width;
	margin: 0 auto;
	padding: 0 $box-side-pad;	// changes with page width so starting point is a var

	@include for-tablet-portrait-up {
		padding: 0 calc(#{$box-side-pad}*1.5);
	}
	// @include for-tablet-landscape-up {	
	// }
	@include for-desktop-up {	
		padding: 0;
	}
}

.box .cell {
	@media ($debug) {
		background-color: rgba(0, 255, 255, 0.2);
		outline: solid 3px skyblue;
		outline-offset: -3px;
	}
		
	& + .cell {											// mobile cells in col, use top padding
		margin-top: 2em;
	}
	@include for-tablet-landscape-up {					// > mobile cells in rows, use top left except for first
		& + .cell {
			margin-left: 3em;
			margin-top: 0;
		}
	}
}


@include for-tablet-landscape-up {	
	.cell--third {
		max-width: 34%;
	}
	.cell--two-thirds {
		max-width: 66%;
	}
}


/* 		NOTE ON HEADINGS
		H1 and H6 are interchangeable so we can choose which one is sending the H1 signal for SEO.
		They behave differetly if they are in the .title section or not.
*/

main section:not(.title) .box .cell  {
	// &:first-child h1:first-child,
	// &:first-child h6:first-child {			// Remove heading top margin for first heading in first cell 
	// 	margin-top: 0em;
	// }
	font-size: 1rem;
	//============================================
	& h1,
	& h6 {
		font-family: 'Montserrat', sans-serif;
		font-weight: 100;
		font-size: 2.6em;
		margin: 0 0 0.5em 0;
		letter-spacing: -1px;
		line-height: 1.2;
		text-transform: uppercase;
		& span {
			font-weight: 600;
			white-space: nowrap;
			@include for-watch-only {
				white-space: normal;
			}
			font-size: 1.03em;
			@include for-tablet-landscape-up {
				font-size: 0.97em;
			}
		}
	}
	& h2 {
		font-family: 'Montserrat', sans-serif;
		font-weight: 100;
		font-size: 1.8em;
		margin: 0.5em 0 0.5em -0.1em;
		text-transform: uppercase;
		letter-spacing: -0.5px;
		color: $hero;
		line-height: 1.2;
		&.reverse {
			color: $alpha-white-06;
		}
		& span { 
			font-weight: 600;
		}
		@include for-tablet-landscape-up {		
			&:first-child {
				margin-top: -5px;				// Used to align heading to top of image when next to it.
			}
		}
	}
	& h3 {
		font-family: 'Montserrat', sans-serif;
		font-weight: 100;
		text-transform: uppercase;
		font-size: 1.8em;
		line-height: 1.2;
		letter-spacing: 0.05em;
		color: $alpha-white-04;
		& span { font-weight: 100; }
	}
	& p,
	& ul > li {
		margin: 0.2em 0 0.5em 0;
		line-height: 1.6em;
		font-size: 1.6em;
		font-family: 'Montserrat', sans-serif;
	}
	& p {
		line-height: 1.4em;
		&.centred {
			text-align: center;
		}
	}
	& a {
		text-decoration: none;
		cursor: pointer;
	}

	@include for-watch-only {			// LIGHTHOUSE: satisfy google lighthouse contrast requirements.
		& p,
		& ul > li {	
			font-size: 2.4em;
		}
		& h2 { 
			font-size: 2.4em;
			&.reverse {
				color: $solid-white;
				opacity: 1;
			}
		}
	}
	// @include for-tablet-portrait-up {	
	// 	& p {
	// 		text-align: left;
	// 	}
	// }
	@include for-tablet-landscape-up {		
		& h1,
		& h6 {
			font-size: 3.2em;
			margin: 0.5em 0 0.7em 0;
		}	
		& h2 {
			font-size: 2.7em;
			margin: 0.5em 0 0.4em -0.1em;
		}
		& h3 {
			font-size: 1.8rem;
		}
		& p,
		& ul > li {
			margin: 0.2em 0 0.5em 0;
			&:first-child {
				margin-top: 0;
			}
		}
		& a {
			font-size: 1.0em;
			line-height: 1.9em;
		}
	}
	@include for-desktop-up {
		& h1,
		& h6 {
			font-size: 4.7em;
			margin: 0.5em 0 1em 0;
			&:first-child {
				margin-top: 0.5em;
			}
		}
	}
	//============================================
}










// LAST GENERIC ADJUSTERS
.panel-section--mb { margin-bottom: 0; }
@include for-tablet-landscape-up {
	.panel-section--mb { margin-bottom: 6em; }
}


.center { text-align: center; }
.hero-text { color: $hero; }
.hero-dark-text { color: $hero-dark; }

.sml-left--lrg-center > * { text-align: left; }
@include for-tablet-portrait-up {
	.sml-left--lrg-center > * { text-align: center; }
}



section.marker {
	color: #E0E0E0;
	@include for-phone-only {					div::after { content: 'for-phone-only'; }						}
	@include for-tablet-portrait-up {			div::after { content: 'for-tablet-portrait-up'; }				}
	@include for-full-menu-up {					div::after { content: 'for-full-menu-up'; }						}	
	@include for-tablet-landscape-up {			div::after { content: 'for-tablet-landscape-up'; }				}
	@include for-desktop-up {					div::after { content: 'for-desktop-up'; }						}
	@include for-big-desktop-up {				div::after { content: 'for-big-desktop-up'; }					}
}
