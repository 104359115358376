
// CLIENT SPECIFIC

$rpf-green-mist-light:	#c6c9c4;
$rpf-green-mist-faint: rgba(122, 138, 107, 0.15);
$rpf-green-mist:		#888F81;
$rpf-green-mist-dim:	#6f7a65;
$rpf-green-mist-strong:	#5b854e;
$rpf-green-mist-dark:	#505947;
$rpf-grey-light:		#E6E6E6;
$rpf-grey-dark:			#666666;
$rpf-blue-light:		#66b5d4;
//$rpf-blue-pale:	        rgb(212, 219, 233);
$rpf-blue:				#486C7A;
$rpf-blue-dark:			#1e4250;
$rpf-blue-strong:		#50879c;
$rpf-rust-light:		#dd3f17;
$rpf-rust:				#8F5242;
$rpf-rust-dark:			#68362a;
$rpf-rust-strong:		#B8573D;
$rpf-straw-bright:		#f8ca3f;
$rpf-straw-pale:		rgba(173, 154, 97, .15);
$rpf-straw:				#AD9A61;
$rpf-straw-dark:		#88753c;
$rpf-straw-strong:		#CCAE4C;
$rpf-straw-heavy:		#ad8501;


$rpf-blue-pale:	rgb(225, 239, 242);
$rpf-blue-steel-light:	rgb(225, 239, 242);
$rpf-blue-steel:		rgb(170, 188, 191);

// NAMED COLOURS - do a find and replace if names need to change by client

$hero:					$rpf-green-mist;
$hero-dim:				$rpf-green-mist-dim;
$hero-faint:			#F2F2F2;//$rpf-green-mist-faint;
$hero-strong:			$rpf-green-mist-strong;
$hero-ultra-light:		rgb(228, 233, 224);
$hero-light:			rgb(160, 168, 151);
$hero-dark:				$rpf-green-mist-dark;
$hero-contrast:			$rpf-rust;
$hero-highlight:		$rpf-straw;
$hero-highlight-bright:	$rpf-straw-strong;
$hero-highlight-heavy:	$rpf-straw-heavy;
$hero-highlight-dark:	$rpf-straw-dark;
//$hero-fg:				$rpf-green-mist-light;
$alt-primary:			$rpf-rust;
$alt-primary-dark:		$rpf-rust-dark;
$alt-primary-strong:	$rpf-rust-strong;
$alt-secondary:			$rpf-blue;
$alt-secondary-dark:	$rpf-blue-dark;
$alt-secondary-strong:	$rpf-blue-strong;
$alt-tertiary:			$rpf-straw;
$alt-tertiary-dark:		$rpf-straw-dark;
$alt-tertiary-strong:	$rpf-straw-strong;

// REFINED NAMED COLOURS
$hero-hover:			$hero-dark;
$contrast--faint:		$rpf-straw-pale;
$contrast:				$rpf-straw;
$contrast--bright:		$rpf-straw-bright;
$contrast--hover:		$rpf-straw-strong;
$contrast--dark:		$rpf-straw-dark;
$support:				$rpf-blue;
$support--hover:		$rpf-blue-dark;

// GENERIC
$ultra-light-grey: 		#F8F8F8;
$light-grey: 			#DFDFDF;
$mid-grey: 				#828282;
$slate-grey: 			#474A4D;
$dark-slate-grey: 		#3A3B3D;
$warm-black: 			#181415;
$solid-black:			#000000;
$solid-white:			#FFFFFF;
$alpha-white-01:		rgba(255, 255, 255, 0.1);
$alpha-white-02:		rgba(255, 255, 255, 0.2);
$alpha-white-03:		rgba(255, 255, 255, 0.3);
$alpha-white-04:		rgba(255, 255, 255, 0.4);
$alpha-white-06:		rgba(255, 255, 255, 0.6);
$alpha-white-08:		rgba(255, 255, 255, 0.8);
$warning-text:			#ee6644;

// ASSIGNED COLOURS
$header-bg--big:		#FFF;					  // Background colour of main header in its large size.
$header-bg--small:		$hero;//-dim;
$header-fg--big:		$hero;						// Foreground colour of main header in its large size.
$header-fg--small:		#FFF;					  // Foreground colour of main header in its large size.
$header-border-bottom:	$hero-light solid 2px;
$header-bg:				$header-bg--big;			// DEPR - moved to ---big as default
$header-fg:				$header-fg--big;			// DEPR - moved to ---big as default



$nav-active-button--big:		rgba(100,100,100,.05);
$nav-active-button--small:		rgba(100,100,100,.15);
$nav-hover-button--big:			$contrast--faint;
$nav-hover-button--small:		rgba(0,0,0,0.05);
$nav-hover-text:				$hero;
$nav-hover-text--small:			$alpha-white-08;
$nav-hover-link:				$contrast--hover;
$nav-active-link:				$contrast--hover;

$footer-bg:						$dark-slate-grey;

$fast-ease:						0.1s ease;
$default-ease:					0.7s ease;
$slow-ease:						2s ease;
$page-max-width:				1140px;
$page-max-width--half:			570px;
$box-side-pad:					2em;

$container-max-width:			$page-max-width;
$container-max-width--half:		$page-max-width--half;
$showcase-max-width:			1220px;
$small-header-below:			480px;

$header-height--big:			200px;
$header-height--small:			70px;
$header-brand-width: 			300px;
$header-brand-width--small:		300px;
$header-brand-height--small:	65px;
$header-brand-height--big: 		100px;
$header-height:					$header-height--big;			// DEPR - moved to ---big as default


// Nav Menu
$nav-height--big:				45px;//60px;
$nav-height--small:				45px;//38px;
$hamburger-bg--big:					#FFF;
$hamburger-bg--small:			$hero;
$hamburger-fg--big:				$hero;
$hamburger-fg--small:			#FFF;

// BANNER
$main-top-normal:			$header-height;
$main-top-small:			$header-height--small;
$main-top-mobile:			$header-height--small;

$teaser-column-fill: 		#FFFF;