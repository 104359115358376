@import "../../styles/variables.scss";
@import "../../styles/media.scss";


.banner {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	&--base {
		justify-content: flex-end;
	}
	&--blank {												// pages that have no banner still need content pushed down under nav menu height
		flex: 0 0 auto;
		height: $main-top-mobile;
		transition: height $default-ease;
		.header--big & {
			height: $main-top-normal;
			@include for-tablet-portrait-up {				// this stops the jump of screen position when scrolling to header small in wide view
				height: $main-top-normal;
			}
			@include for-phone-only {
				height: $main-top-mobile;
			}
		}
	}
	&--map {												// pages that have MAP banner still - use taller banner area for map
		flex: 0 0 auto;
		height: $main-top-small;	// unlike banner images, we can simply make thee map stretch under the header inso avoiding the resize and reduce jankiness
		background-color: #E5E3DF;		// map background default
	}
	&--slider {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: relative;
		background-size: cover;
		z-index: 1;
		padding-top: 0;
		margin-top: calc( #{$main-top-small} - 5px);		// Smooth-Slider-Scroll: Required even though you think it's not... it is.
		transition: margin-top $default-ease;
		.header--big & {
			margin-top: calc( #{$main-top-normal} - 5px); // Smooth-Slider-Scroll: uses overlap to avoid visible gap when scrolling up to full menu again
			transition: margin-top $default-ease;	
		}
	}
}
