@import "../../styles/variables.scss";
@import "../../styles/media.scss";




.hamburger-canvas {
	display: none;
	//position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(255,255,255,0.3);
	z-index: 100;
	&--active {
		display: block;
	}
}



/* MENU STYLES - mobile menu */
.hamburger-wrap {
	& .hamburger {
		position: absolute;
		top: 0;
		right: 10px;
		z-index: 1;
		width: 56px;
		height: 56px;
		padding: 1rem;
		background: $hamburger-bg--small;
		@include for-watch-only {			// LIGHTHOUSE: satisfy google lighthouse contrast requirements.
			background: $hero-dim;
		}
		align-items: center;
		justify-content: center;
		transition: all 1s ease;
		display: flex;
		cursor: pointer;
		@include for-full-menu-up {
			display: none;
		}
		& > div.hamburger-lines { 							/* Hamburger Line */
			position: relative;
			flex: none;
			width: 100%;
			height: 2px;
			background: $hamburger-fg--small;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.4s ease;
			&::before,
			&::after {			 			 /* Hamburger Lines - Top & Bottom */
				content: '';
				position: absolute;
				z-index: 1;
				top: -10px;
				width: 100%;
				height: 2px;
				background: inherit;
				right: 0;					/* Fixes old iOS bug on hamburger buns being shifted right */
			}
			&::after {  					/* Moves Line Down just for after */
				top: 10px;
			}
		}
		.header--big & {
			background: $hamburger-bg--big;		
				
			& > div.hamburger-lines { 								/* Hamburger Lines */
				background: $hamburger-fg--big;
			}
		}
		
		&.hamburger-open > div.hamburger-lines {  			/* Toggler Animation */
			transform: rotate(135deg);
			&::before,		  		  		/* Turns Lines Into X */
			&::after {
				top: 0;
				transform: rotate(90deg);
			}
		}
		&.hamburger-open:hover > div {		/* Rotate On Hover When Checked */
			transform: rotate(225deg);
		}
	}
	& .hamburger-menu {
		display: flex;
		position: fixed;
		z-index: 150;
		top: $header-height--small;
		right: 0;
		min-width: 350px;
		overflow: hidden;
		align-items: center;
		justify-content: flex-start;
		background: $hero;
		padding: 5px 15px;

		opacity: 0;
		visibility: hidden;
		transition: visibility $default-ease, opacity $default-ease, top $default-ease;
		@include for-phone-only {
			width: 100%; 						// required for mobile to utilise 100% via MQ in .hamburger-wrap .menu > div
		}
		font-size: 1.8rem;
		.header--big & {
			top: $header-height--big;
			transition: top $default-ease;
		}
				& > ul.menu-panel,
				& > ul > li {
					list-style: none;
					color: #fff;
					font-size: 1em;
					padding: .3rem 1rem;
					z-index: 85;
					-webkit-tap-highlight-color: transparent;
					position: relative;
					cursor: pointer;
					&.active {
						color: $rpf-green-mist-dark;
					}
					& > ul {
						position: relative;
						z-index: 2100;
						padding-inline-start: 1em;
						& > li {
							list-style: none;
							padding: .3rem;
							& a,
							& div.menu-item-link {
								color: #fff;
								text-decoration: none;
								&:hover {
									color: #ffd700;
								}
							}
						}
					}
				}
				& > ul > li > a,
				& > ul > li > div.menu-item-link {
					color: inherit;
					text-decoration: none;
					transition: color $default-ease;
					&:hover {
						color: #ffd700;
					}
				}
	}
	& .hamburger-menu--open {
		opacity: 1;
		visibility: visible;
		transition: visibility $default-ease, opacity $default-ease, top $default-ease;		
	}
}