@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.showcase {
	font-size: 1.2rem;
	max-width: $showcase-max-width;
	align-items: center;	// in column, centre the boxes with each other
	padding: 2rem 1rem 3rem 1rem;
	& div.showcase-panel {
		background-color: white;
		cursor: pointer;
		max-width: 85vw;
		margin: 15px 0;						// turn off the default flx margin
		padding: 20px 30px 30px 30px;
		& h2 {
			font-family: sans-serif;
			text-transform: uppercase;
			font-size: 1.5em;
			font-weight: 400;
			color: $warm-black;
			& span {
				font-weight: 900;
			}
		}
		& p, a {
			font-size: 1.2em;
			line-height: 1.4em;
		}
		& a {
			color: $hero-highlight-heavy;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
				color: $contrast;
			}
		}
		& .icon-teaser {
			fill: $hero-highlight-bright;
			width: 5rem;
			height: 5rem;
			margin: 0;
		}
		&:hover {
			box-shadow: 5px 10px 30px rgba(200, 200, 200, .4);
			z-index: 10;
		}

		@include for-watch-only {			// LIGHTHOUSE: satisfy google lighthouse contrast requirements.
			& p, a {
				font-size: 2em;
				line-height: 1.2em;
			}
			padding: 20px 10px 30px 10px;	
			& h2 {
				font-size: 2em;
				line-height: 1.1em;
			}		
		}
		
	}
	@include for-tablet-landscape-up {
		padding: 4rem 6rem 6rem 6rem;
		align-items: flex-start;	// in row, align boxes to their tops
		& div.showcase-panel {
			margin: 0;
			& + .showcase-panel {
				margin-left: 40px;
			}
			& h2 {
				font-size: 1.5em;
			}
		}
	}

}
