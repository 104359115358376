@import "../../styles/variables.scss";
@import "../../styles/media.scss";



.header-item--brand a {
	text-decoration: none;
	&:visited {
		color: #000;
	}
	& .brand-box {
		align-items: center;
		font-size: 1.6rem;
		& svg {
			&.brand-logo {
				width: 65px; 							// marry with $header-brand-height--small - doesn't have to be same but would normally be no bigger (but coould be)
				transition: width $default-ease;
				& .faithfull-ba--logo-fg {
					fill: $solid-white;
				}
				& .faithfull-ba--logo-bg {
					fill: $hero;
				}
			}
		}
		& .brand-masthead {
			font-size: 1.2em;
			line-height: 0.9em;
			color: #000;
			font-family: Montserrat, san-serif;
			margin-left: 0.5em;
			margin-top: 0.3em;
			text-transform: uppercase;
			transition: font-size $default-ease;
			& .brand-masthead-suplementary {
				display: flex;
				justify-content: space-between;
				font-size: 0.48em;
				margin-left: 0.12em;
				& .brand-masthead-secondary {
					letter-spacing: 0.05em;
					color: #FFF;
				}
				& .brand-masthead-tertiary {
					letter-spacing: 0.04em;
					color: #FFF;
				}
			}

		}
	}
	.header--big & {
		& .brand-box {
			& svg {
				&.brand-logo {
					transition: width $default-ease;
					width: 100px;
				}
			}
		}
		& .brand-masthead {
			transition: font-size $default-ease;
			font-size: 2.7em;
			line-height: 0.8em;
			& .brand-masthead-suplementary {
				& .brand-masthead-secondary,
				& .brand-masthead-tertiary {
					color: $hero;
					transition: color $default-ease;
				}
			}
		}
	}
	@include for-tablet-portrait-up {
		& .brand-box {
			font-size: 2.3rem;
			// & svg {
			// 	&.brand-logo {
			// 		width: 115px;
			// 	}
			// }
		}
		& .brand-masthead {
			margin-left: 0.5em;
		}
	}
}


section {
	& .brand-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 1.6rem;
		& svg {
			&.brand-logo {
				max-width: 250px;
				transition: width $default-ease;
				& .faithfull-ba--logo-fg {
					fill: $solid-white;
				}
				& .faithfull-ba--logo-bg {
					fill: $hero;
				}
			}
		}
		& .brand-masthead {
			color: #000;
			font-family: Montserrat, san-serif;
			font-size: 2.7em;
			line-height: 0.8em;
			margin-top: 0.3em;
			text-transform: uppercase;
			transition: font-size $default-ease;
			& .brand-masthead-suplementary {
				display: flex;
				justify-content: flex-start;
				font-size: 0.48em;
				margin-left: 0.12em;
				& .brand-masthead-secondary {
					letter-spacing: 0.05em;
					color: $alpha-white-06;
					margin-right: 0.7em;
				}
				& .brand-masthead-tertiary {
					letter-spacing: 0.04em;
					color: $alpha-white-06;
				}
			}

			@include for-watch-only {			// LIGHTHOUSE: satisfy google lighthouse contrast requirements.				
				font-size: 3.5em;
			}
		}
	}
}




@include for-watch-only {			// LIGHTHOUSE: satisfy google lighthouse contrast requirements.
	.header-item--brand a {
		& .brand-box svg.brand-logo .faithfull-ba--logo-bg {
			fill: $hero-dim;
		}
		& .brand-masthead {
			font-size: 1.9em;
		}
	}
	section .brand-box  .brand-masthead {
		font-size: 3.5em;
		& .brand-masthead-suplementary {
			& .brand-masthead-secondary,
			& .brand-masthead-tertiary {
				color: #FFF;
			}
		}
	}
}


.footer-brand.brand-box {
	@media ($debug) {
		background-color: rgba($color: #fffb00, $alpha: 0.2);
	}
	align-items: center;
	font-size: 1.6rem;
	& svg {
		@media ($debug) {
			background-color: rgba($color: #ff9900, $alpha: 0.2);
		}
		&.brand-logo {
			min-width: 90px;
			max-width: 200px;
			transition: min-width $default-ease;
			& .faithfull-ba--logo-fg {
				fill: $solid-white;
				opacity: 0.8;
			}
			& .faithfull-ba--logo-bg {
				fill: $solid-white;
				opacity: 0.2;
			}
		}
	}
	& .brand-masthead {
		@media ($debug) {
			background-color: rgba($color: #1eff00, $alpha: 0.2);
		}
		color: #000;
		font-family: Montserrat, san-serif;
		font-size: 8vw;							// on mobile and below, scale with screen width
		@include for-tablet-portrait-up {		// fixed size above mobile width
			font-size: 2.7em;		
		}
		line-height: 0.8em;
		margin-left: -0.1em;
		margin-top: 0.3em;
		text-transform: uppercase;
		transition: font-size $default-ease;
		& .brand-masthead-primary {
			letter-spacing: 0.05em;
			color: $solid-white;
			opacity: 0.8;
		}
		& .brand-masthead-suplementary {
			display: flex;
			justify-content: flex-start;
			font-size: 0.48em;
			margin-left: 0.12em;
			& .brand-masthead-secondary {
				letter-spacing: 0.05em;
				color: $solid-white;
				opacity: 0.5;
				margin-right: 1em;
			}
			& .brand-masthead-tertiary {
				letter-spacing: 0.04em;
				color: $solid-white;
				opacity: 0.5;
			}
		}
	}
	
}